import './App.css';
import './App.scss';
import './responsive.scss';
// import 'semantic-ui-css/semantic.min.css'
// import { BrowserRouter } from 'react-router-dom';

import React from 'react';
import Layout from './components/Layout/index.jsx';

function App() {
	return (
		// <BrowserRouter>
		<div className='App'>
			<Layout />
			<div className="navigation__scrim"></div>
		</div>
		//  </BrowserRouter>
	);
}

export default App;
