import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './sectionFour.scss';
import card1 from '../../../resources/oil-and-gas-card.png';
import card2 from '../../../resources/banking-and-finance-card.png';
import card3 from '../../../resources/pure-equity-card.png';
import oilGasDiscountImg from '../../../resources/20-per-card-yellow.png';
import bankingAndFinanceDiscountImg from '../../../resources/20-per-card-red.png';
import pureEquityDiscountImg from '../../../resources/20-per-card-blue.png';
import VisibilitySensor from 'react-visibility-sensor';
import clientImg from '../../../resources/homepage/fold4_clients_logo.png';

class SectionFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibilityOilandGas: false,
      visibilityBankingFinancial: false,
      visibilityPrivate: false,
  }

  this.handleDiscoverProductClick = this.handleDiscoverProductClick.bind(this)
  }
  

  handleDiscoverProductClick() {
    if (typeof document !== 'undefined') {
      window.location = '/product';
    }
  };

  render() {
    return (
      <div id='sectionFour-wrapper'>
        <div className="sectionFour-client">
          <div className="heading">Extensive Global Client Experience</div>
          <div className="clientImg"><img src={clientImg}/></div>
        </div>
        <div id='sectionFour'>
          <div id='sectionFour-Head'>
            We Deliver Exceptional Value to Our Clients.
          </div>
          <p id='sectionFour-Head-Text'>
            EACIIT solutions have been deployed in multiple, large, global organizations across
            geographies, yielding quantifiable, significant gains for clients. Years of experience equip us to
            understand your business ambition, and our suite of high-impact performance improvement tools help
            us deliver it with speed, scale, and agility. 
          </p>
          <a id='sectionFour-Head-link' href='/resources'>
            Discover How Organizations are Using ECFM to Drive Improvement ->
          </a>
{/*          <div className='sectionFour-content-box content-box-1'>
            <div className='sectionFour-content-box-left'>
              <h3 className='sectionFour-content-box-left-heading'>
                Oil and Gas
              </h3>
              <ul className='sectionFour-content-box-left-list'>
                <li>Efficient planning and forecasting</li>
                <li>Reduce downtime and cost of upstream activities</li>
                <li>
                  Linkage between operational, field, and financial performance
                </li>
                <li>Optimization of requirements, resources, and supply chain</li>
              </ul>
            </div>
            <div className='sectionFour-content-box-right'>
                <img
                  className='sectionFour-content-box-right-img'
                  src={card1}
                  alt='oil and gas'
              />
                <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { this.setState({visibilityOilandGas: isVisible}) }}>
                  <Fade top duration={2000} spy={this.state.visibilityOilandGas} distance="30px">
                    <img
                      className='discount-card'
                      src={oilGasDiscountImg}
                      alt='discount'
                    />
                  </Fade>
                </VisibilitySensor>
              
            </div>
          </div>
          <div className='sectionFour-content-box-rev content-box-2'>
            <div className='sectionFour-content-box-rev-left'>
              <img
                className='sectionFour-content-box-rev-left-img'
                src={card2}
                alt='banking'
              />
                <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { this.setState({visibilityBankingFinancial: isVisible}) }}>
                  <Fade top duration={2000} spy={this.state.visibilityBankingFinancial} distance="30px">
                     <img
                      className='discount-card'
                      src={bankingAndFinanceDiscountImg}
                      alt='discount'
                    />
                  </Fade>
                </VisibilitySensor>
            </div>
            <div className='sectionFour-content-box-rev-right'>
              <h3 className='sectionFour-content-box-rev-right-heading'>
                Banking and Financial Services
              </h3>
              <ul className='sectionFour-content-box-rev-right-list'>
                <li>Improvement in capital efficiency and ROI</li>
                <li>
                  Integration of customer, product, risk, and profit performance to provide balanced growth view
                </li>
                <li>
                  Maximization of profitability across customers, products, segments, and channels
                </li>
              </ul>
            </div>
          </div>
          <div className='sectionFour-content-box content-box-3'>
            <div className='sectionFour-content-box-left'>
              <h3 className='sectionFour-content-box-left-heading'>
                Private Equity
              </h3>
              <ul className='sectionFour-content-box-left-list'>
                <li>
                  Full range of analytics, insights, and dashboards to manage fund
                  performance and reporting
                </li>
                <li>
                  Improved profitability and working capital in operating
                  companies
                </li>
                <li>
                  Acceleration and increase in cash flow returns from the
                  investment portfolio
                </li>
              </ul>
            </div>
            <div className='sectionFour-content-box-right'>
              <img
                className='sectionFour-content-box-right-img'
                src={card3}
                alt='equity'
              />
              <VisibilitySensor partialVisibility={true} onChange={(isVisible) => { this.setState({visibilityPrivate: isVisible}) }}>
                  <Fade top duration={2000} spy={this.state.visibilityPrivate} distance="30px">
                    <img
                      className='discount-card'
                      src={pureEquityDiscountImg}
                      alt='discount'
                    />
                  </Fade>
                </VisibilitySensor>
            </div>
          </div>*/}
        </div>
      </div>
    );
  }
};

export default SectionFour;