import React from 'react';
import NavBar from '../NavBar/index.jsx';
import Footer from '../Footer/index.jsx';

export class LayoutComponent extends React.Component {

	render() {
		return (
			<section>
				<NavBar />
				<section className='footer-section-bottom'>
					<Footer />
				</section>
			</section>
		);
	}
}

export default LayoutComponent;
