import React from 'react';
import './SectionOne.scss';
import platformHeaderImageBg from '../../../resources/platform-header-image-bg.png';
import platformHeaderImage from '../../../resources/platform-header-image-update.png';
import icon1 from '../../../resources/platform/icon1.png';
import icon2 from '../../../resources/platform/icon2.png';
import icon3 from '../../../resources/platform/icon3.png';
import icon4 from '../../../resources/platform/icon4.png';
import icon5 from '../../../resources/platform/icon5.png';

const SectionOne = () => {
	return (
		<div className='PlatformSectionOne'>

			<div className='PlatformSectionOne-header'>
				<p className='PlatformSectionOne-header-1'>The proven AI based xP&A Platform</p>
				<h1 className='PlatformSectionOne-header-2'>
					For Enterprise Performance Management
				</h1>
				<p className='PlatformSectionOne-sub-header'>
					EACIIT brings you a one-stop platform that powers end-to-end performance improvement products <br/> and solutions, supporting the entire value chain of planning, forecasting, execution, and reporting
				</p>
			</div>

			<div className='PlatformSectionOne-card'>
				<div className="box-card card-one">
					<div className="card-icon">
						<img
							src={icon1}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Pre-built, open source platform already proven with large, global MNCs
					</div>
				</div>
				<div className="box-card card-two">
					<div className="card-icon">
						<img
							src={icon2}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Data from multiple sources blended and curated into single source of truth
					</div>
				</div>
				<div className="box-card card-three">
					<div className="card-icon">
						<img
							src={icon3}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						AI-based models, human-centered design, intuitive interfaces
					</div>
				</div>
				<div className="box-card card-four">
					<div className="card-icon">
						<img
							src={icon4}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Intelligent integration layer providing digitization, BI and AI enablement
					</div>
				</div>
				<div className="box-card card-five">
					<div className="card-icon">
						<img
							src={icon5}
							alt='box-icon'
						/>
					</div>
					<div className="card-description">
						Functional and technical scale to keep up with evolving business requirements
					</div>
				</div>
			</div>

			<div className='PlatformSectionOne-content'>
				<p className='para-top'>Automated data from multiple systems in a harmonized and continuous manner</p>
				<img className ='image' src={platformHeaderImage} alt='Platform title' />
				{/*<p className='para'>Combines financial and operational data from ERP and other sources</p>*/}
			</div>
		</div>
	);
};

export default SectionOne;
