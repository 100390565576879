import React from 'react';
import './sectionThree.scss';
import icon1 from '../../../resources/homepage/fold2_1.png';
import icon2 from '../../../resources/homepage/fold2_2.png';
import icon3 from '../../../resources/homepage/fold2_3.png';
import icon4 from '../../../resources/homepage/fold2_4.png';
import icon5 from '../../../resources/homepage/fold2_5.png';
import icon6 from '../../../resources/homepage/fold2_6.png';

const SectionThree = () => {
	const handleDiscoverKeyFocusClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/keyfocus';
		}
	};

	const handleDiscoverIndustryClick = () => {
		if (typeof document !== 'undefined') {
			window.location = '/industry';
		}
	};

	const routeChange = (path) => {
		if (window) {
			window.location = '/' + path;
		}
		document.getElementById('navBar').scrollIntoView();
	};

	return (
		<div id='sectionThree'>
			<div id='sectionThree-content'>
				<div id='sectionThree-head'>Single Vendor Integrated Platform</div>
				<div id='sectionThree-head-note' className="text-orange">(Data, BI, AI and Collaboration)</div>
				<div id='sectionThree-subhead'>
					No more toggling! Our high-impact products, built on the back of an open source/integrated platform, enable you to view all your data, assess performance, ideate, collaborate, coordinate and manage improvement initiatives through one common application. Work becomes simpler.
				</div>
				{/*<div id='sectionThree-card'>
					<div id='sectionThree-card-1'>
						<div id='sectionThree-card-1-top'>
							<img src={icon1} id='card-1-icon' alt='card1-icon' />
							<div id='sectionThree-card-1-head'>
								<div id='card-1-head1'>GET VISIBILITY:</div>
								<div id='card-1-head2'>See The Data You Need</div>
							</div>
						</div>
						<div id='card-1-para'>
							Monetize data with timely, smart decisions <br />
							<br />
							Gain free cash flow visibility across business units, projects,
							customers <br />
							<br />
							Benchmark KPIs to target and expected performance <br />
						</div>
					</div>
					<div id='sectionThree-card-2'>
						<div id='sectionThree-card-2-top'>
							<img src={icon2} id='card-2-icon' alt='card2-icon' />
							<div id='sectionThree-card-2-head'>
								<div id='card-2-head1'>AUTOMATE & ANALYSE:</div>
								<div id='card-2-head2'>Improve Efficiency</div>
							</div>
						</div>
						<div id='card-2-para'>
							Automate workflows, get actionable insights, real-time updates{' '}
							<br />
							<br />
							Get interactive dashboards, generate predictive scenarios <br />
							<br />
							Generate customised reports <br />
						</div>
					</div>
					<div id='sectionThree-card-3'>
						<div id='sectionThree-card-2-top'>
							<img src={icon3} id='card-3-icon' alt='card3-icon' />
							<div id='sectionThree-card-3-head'>
								<div id='card-3-head1'>ALIGN TEAMS:</div>
								<div id='card-3-head2'>Drive growth</div>
							</div>
						</div>
						<div id='card-3-para'>
							Enable collaboration and ideation across all levels <br />
							<br />
							Plan, forecast requirements, and optimize resources
							<br />
							<br />
							Link sales, operational and financial performance <br />
						</div>
					</div>
				</div>*/}
				<div id='sectionThree-card'>
					<div id="sectionThree-card-top">
						<div id='sectionThree-card-1'>
							<div id='sectionThree-card-1-top'>
								<img src={icon1} id='card-1-icon' alt='card1-icon' />
							</div>
							<div id='card-1-para'>
								Alignment between operational plans and financial results
							</div>
						</div>
						<div id='sectionThree-card-2'>
							<div id='sectionThree-card-2-top'>
								<img src={icon2} id='card-2-icon' alt='card2-icon' />
							</div>
							<div id='card-2-para'>
								Hyperautomation in planning, analysis and reporting
							</div>
						</div>
						<div id='sectionThree-card-3'>
							<div id='sectionThree-card-2-top'>
								<img src={icon3} id='card-3-icon' alt='card3-icon' />
							</div>
							<div id='card-3-para'>
								Simulation and scenario modelling
							</div>
						</div>
					</div>
					<div id="sectionThree-card-bottom">
						<div id='sectionThree-card-4'>
							<div id='sectionThree-card-1-top'>
								<img src={icon4} id='card-1-icon' alt='card1-icon' />
							</div>
							<div id='card-1-para'>
								Analytics studio <br/> (user self service)
							</div>
						</div>
						<div id='sectionThree-card-5'>
							<div id='sectionThree-card-2-top'>
								<img src={icon5} id='card-2-icon' alt='card2-icon' />
							</div>
							<div id='card-2-para'>
								Initiatives management and Instant messaging
							</div>
						</div>
						<div id='sectionThree-card-6'>
							<div id='sectionThree-card-2-top'>
								<img src={icon6} id='card-3-icon' alt='card3-icon' />
							</div>
							<div id='card-3-para'>
								AI boosts strategies to achieve plans and targets
							</div>
						</div>
					</div>
				</div>
				<div id='sectionThree-card-footer'>
					{/*<p>The EACIIT Approach: Maximum Value, Minimum Effort</p>*/}
					<button
						onClick={() => {
							routeChange('platform');
						}}
					>
						Discover More
					</button>
				</div>
			</div>
		</div>
	);
};

export default SectionThree;
