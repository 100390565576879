import React, { useState } from "react";
import { Button, Dropdown, Icon, List, Menu, Segment, Transition, Message } from "semantic-ui-react";
import { Redirect, Route, Switch } from "react-router-dom";
import Company from "../Company/index.js";
import ContactUs from "../Contact/index.jsx";
import Databrowser from "../Databrowser/index.jsx";
import Features from "../Features/index.jsx";
import Home from "../Home";
import Industry from "../Industry/index.jsx";
import KeyFocus from "../KeyFocus/index.jsx";
import Platform from "../Platform";
import Product from "../Product";
import Resources from "../Resourses/index.js";
import Recognition from "../Recognition/index.jsx";
import Solutions from "../Solutions";
import Technology from "../Technology/index.jsx";
import DataQualityTool from "../DataQualityTool/index.js";
import Navlogo from "../../resources/eaciit-logo.png";
import "./navBar.css";
import "./navBar.scss";

export default function NavBar() {
    const [activeItem, setactiveItem] = useState("home");
    const [openMenu, setOpenMenu] = useState(false);
    const [openPlatform, setOpenPlatform] = useState(false);
    const [openSolutions, setOpenSolutions] = useState(false);
    const [openResources, setOpenResources] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);

    const navbarStyles = {
        backgroundColor: "white",
        borderRadius: "0px",
        position: "fixed",
        zIndex: "1000",
        width: "100%",
        height: "70px",
        top: "0",
        left: "0"
    };

    const logoItemStyle = {
        width: "150px",
    };

    const menuItemStyle = {
        fontFamily: "Roboto",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "20px",
        color: "#1C2640",
        cursor: "pointer",
        minWidth: "120px",
    };

    const contactButtonStyle = {
        background: "#1c2640",
        borderRadius: "3px",
        padding: "10px 20px",
        float: "none",
        right: "-160px",
        color: "#ffffff !important",
    };

    const routeChange = (path) => {
        setactiveItem(path);
        if (window) {
            window.location = "/" + path;
        }
        document.getElementById("navBar").scrollIntoView();
    };

    const handleClickMenu = () => setOpenMenu(!openMenu);

    const handleOpenPlatform = () => setOpenPlatform(!openPlatform);

    const handleOpenSolutions = () => setOpenSolutions(!openSolutions);

    const handleOpenResources = () => setOpenResources(!openResources);

    const handleOpenProduct = () => setOpenProduct(!openProduct);

    const handleOpenMenu = () => {
        let element = document.getElementsByClassName("navigation__scrim")[0];
        element.classList.add("active");
        // document.querySelectorAll(".active.visible.item.dropdown.menu-item")[0].classList.add("lined")
    };

    const handleCloseMenu = () => {
        let element = document.getElementsByClassName("navigation__scrim")[0];
        element.classList.remove("active");
        // document.querySelectorAll(".active.visible.item.dropdown.menu-item")[0].classList.remove("lined")
    };

    React.useEffect(() => {
        if (typeof document !== "undefined") {
            let pathName = window.location.pathname.split("/")[1];
            setactiveItem(pathName);
        }
        // document.getElementById('platformMenuParent').onclick = function () {
        // 	routeChange('platform');
        // };
    }, []);

    //  useEffect(() => {
    // 	let unlisten

    //  	routeChange(window.location.pathname.split('/')[1])
    //     // Listen for changes to the current location.
    //     unlisten = history.listen((location, action) => {
    //       routeChange(window.location.pathname.split('/')[1])
    //     })

    //     return function cleanup() {
    //       unlisten()
    //     }
    //   }, [])

    return (
        <>
            <div id="navBar">
                <Segment id={"navBarColor"} inverted style={navbarStyles} className={openMenu ? "bg-grey" : "bg-white"}>
                    <Menu inverted secondary>
                        <div className="grouped-menu">
                            <Menu.Item
                                className="company-logo"
                                style={logoItemStyle}
                                // as={Link}
                                onClick={() => routeChange("home")}
                            >
                                <img src={Navlogo} className="navbar-logo" alt="logo-navbar" />
                            </Menu.Item>
                            <Dropdown
                                className={activeItem === "product" || activeItem === "databrowser" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Products"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown">
                                    <Dropdown.Item active={activeItem === "product"}>
                                        <Message className="custom-msg-nav" header="ECFM" onClick={() => routeChange("product")} content="Manage and improve KPIs with free cash flow focus" />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "databrowser"}>
                                        <Message className="custom-msg-nav" header="Analytics Studio" onClick={() => routeChange("databrowser")} content="Data made accessible for everyone" />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "data-quality-tool"}>
                                        <Message className="custom-msg-nav" header="Eaciit Data Quality Tool" onClick={() => routeChange("data-quality-tool")} content="Understand and improve quality of data" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                item
                                text="Platform"
                                className={activeItem === "platform" || activeItem === "technology" || activeItem === "features" ? "active nav-list menu-item" : "nav-list menu-item"}
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown multi-column-menu">
                                    <Dropdown.Item active={activeItem === "platform"}>
                                        <Message
                                            className="custom-msg-nav"
                                            header="Platform Overview"
                                            onClick={() => routeChange("platform")}
                                            content="Pre-built, open source platform utilized by global MNCs"
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "technology"}>
                                        <Message className="custom-msg-nav" header="Technology" onClick={() => routeChange("technology")} content="Speed, Scale & Security" />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "features"}>
                                        <Message
                                            className="custom-msg-nav"
                                            header="Functionality"
                                            onClick={() => routeChange("features")}
                                            content="Automation, AI, BI, Analytics, Collaboration, and more"
                                        />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                className={activeItem === "solutions" || activeItem === "keyfocus" || activeItem === "industry" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Solutions"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown multi-column-menu">
                                    <Dropdown.Item active={activeItem === "solutions"}>
                                        <Message
                                            className="custom-msg-nav"
                                            header="Solutions Overview"
                                            onClick={() => routeChange("solutions")}
                                            content="Powerful digital solutions delivered with speed, scale, and agility"
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "keyfocus"}>
                                        <Message
                                            className="custom-msg-nav"
                                            header="Solutions by Key Focus Areas"
                                            onClick={() => routeChange("keyfocus")}
                                            content="Drive improvements in key focus areas on auto mode​"
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "industry"}>
                                        <Message
                                            className="custom-msg-nav"
                                            header="Solutions by Industry"
                                            onClick={() => routeChange("industry")}
                                            content="Get industry-specific solutions that maximize business results on auto mode​"
                                        />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                className={activeItem === "resources" || activeItem === "recognition" ? "active nav-list menu-item" : "nav-list menu-item"}
                                item
                                text="Resources"
                                onOpen={() => {
                                    handleOpenMenu();
                                }}
                                onClose={() => {
                                    handleCloseMenu();
                                }}
                            >
                                <Dropdown.Menu className="animate fadeInDown">
                                    <Dropdown.Item active={activeItem === "resources"}>
                                        <Message className="custom-msg-nav" header="Customer Stories" onClick={() => routeChange("resources")} content="See how EACIIT creates value for clients" />
                                    </Dropdown.Item>
                                    <Dropdown.Item active={activeItem === "recognition"}>
                                        <Message className="custom-msg-nav" header="Recognition" onClick={() => routeChange("recognition")} content="" />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Menu.Item
                                style={menuItemStyle}
                                // name='Company'
                                className="nav-list"
                                active={activeItem === "company"}
                                // as={Link}
                                // to='/company'
                                onClick={() => routeChange("company")}
                            >
                                <span className="nav-list-text">Company</span>
                            </Menu.Item>
                        </div>
                        <div className="right-menu">
                            <Menu.Item
                                style={contactButtonStyle}
                                position="right"
                                name="Contact Us"
                                className="contact-us nav-list"
                                // as={Link}
                                // to='/contact'
                                onClick={() => routeChange("contact")}
                            />
                        </div>
                    </Menu>
                    <div className="icon-menu-list">
                        <Button className="contact-us" onClick={() => routeChange("contact")}>
                            Contact Us
                        </Button>
                        <Icon name={openMenu ? "close" : "bars"} onClick={handleClickMenu} />
                    </div>
                </Segment>
                <div className="mobile-view">
                    <Transition visible={openMenu} animation="fade down" duration={500}>
                        <List divided relaxed verticalAlign="middle" className="navBarMobileScreen">
                            {/*<List.Item
								onClick={() => routeChange('product')}
								active={activeItem === 'product'}
							>
								<List.Content>
									<List.Header>Product</List.Header>
								</List.Content>
							</List.Item>*/}

                            <List.Item active={activeItem === "product"}>
                                <List.Content floated="right">
                                    <Icon name={openProduct ? "angle up" : "angle down"} onClick={handleOpenProduct} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Products</List.Header>
                                </List.Content>
                                <Transition visible={openProduct} animation="fade down" duration={500}>
                                    <List.List>
                                        <List.Item onClick={() => routeChange("product")} active={activeItem === "product"}>
                                            <List.Content>
                                                <List.Header>
                                                    ECFM
                                                    <span className="highlight-box">
                                                        <br />
                                                        Manage and improve KPIs with free cash flow focus
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>

                                        <List.Item onClick={() => routeChange("databrowser")} active={activeItem === "databrowser"}>
                                            <List.Content>
                                                <List.Header>
                                                    Analytics Studio
                                                    <span className="highlight-box">
                                                        <br />
                                                        Data made accessible for everyone
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>

                                        <List.Item onClick={() => routeChange("data-quality-tool")} active={activeItem === "data-quality-tool"}>
                                            <List.Content>
                                                <List.Header>
                                                    Eaciit Data Quality Tool
                                                    <span className="highlight-box">
                                                        <br />
                                                        Understand and improve quality of data
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "platform" || activeItem === "features" || activeItem === "technology"}>
                                <List.Content floated="right">
                                    <Icon name={openPlatform ? "angle up" : "angle down"} onClick={handleOpenPlatform} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Platform</List.Header>
                                </List.Content>
                                <Transition visible={openPlatform} animation="fade down" duration={500}>
                                    <List.List divided>
                                        <List.Item onClick={() => routeChange("platform")} active={activeItem === "platform"}>
                                            <List.Content>
                                                <List.Header>
                                                    Platform Overview{" "}
                                                    <span className="highlight-box">
                                                        <br />
                                                        Pre-built, open source platform utilized by global MNCs
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("technology")} active={activeItem === "technology"}>
                                            <List.Content>
                                                <List.Header>
                                                    Technology
                                                    <span className="highlight-box">
                                                        <br />
                                                        Speed, Scale & Security
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("features")} active={activeItem === "features"}>
                                            <List.Content>
                                                <List.Header>
                                                    Functionality
                                                    <span className="highlight-box">
                                                        <br />
                                                        Automation, AI, BI, Analytics, Collaboration, and more
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "solutions" || activeItem === "industry" || activeItem === "keyfocus"}>
                                <List.Content floated="right">
                                    <Icon name={openSolutions ? "angle up" : "angle down"} onClick={handleOpenSolutions} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Solutions</List.Header>
                                </List.Content>
                                <Transition visible={openSolutions} animation="fade down" duration={500}>
                                    <List.List divided>
                                        <List.Item onClick={() => routeChange("solutions")} active={activeItem === "solutions"}>
                                            <List.Content>
                                                <List.Header>
                                                    Solutions Overview
                                                    <span className="highlight-box">
                                                        <br />
                                                        Powerful digital solutions delivered with speed, scale, and agility
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("industry")} active={activeItem === "industry"}>
                                            <List.Content>
                                                <List.Header>
                                                    Solutions By Industry
                                                    <span className="highlight-box">
                                                        <br />
                                                        Get industry-specific solutions that maximize business results on auto mode​
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("keyfocus")} active={activeItem === "keyfocus"}>
                                            <List.Content>
                                                <List.Header>
                                                    Solutions By Key Focus Areas
                                                    <span className="highlight-box">
                                                        <br />
                                                        Drive improvements in key focus areas on auto mode​
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item active={activeItem === "resources"}>
                                <List.Content floated="right">
                                    <Icon name={openResources ? "angle up" : "angle down"} onClick={handleOpenResources} />
                                </List.Content>
                                <List.Content>
                                    <List.Header>Resources</List.Header>
                                </List.Content>
                                <Transition visible={openResources} animation="fade down" duration={500}>
                                    <List.List>
                                        <List.Item onClick={() => routeChange("resources")} active={activeItem === "resources"}>
                                            <List.Content>
                                                <List.Header>
                                                    Customer Stories
                                                    <span className="highlight-box">
                                                        <br />
                                                        See how EACIIT creates value for clients
                                                    </span>
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                        <List.Item onClick={() => routeChange("recognition")} active={activeItem === "recognition"}>
                                            <List.Content>
                                                <List.Header>Recognition</List.Header>
                                            </List.Content>
                                        </List.Item>
                                    </List.List>
                                </Transition>
                            </List.Item>

                            <List.Item onClick={() => routeChange("company")} active={activeItem === "company"}>
                                <List.Content>
                                    <List.Header>Company</List.Header>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Transition>
                </div>
            </div>

            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        return <Redirect to="/home" />;
                    }}
                />
                <Route path="/home">
                    <Home />
                </Route>
                <Route path="/product">
                    <Product />
                </Route>
                <Route path="/data-quality-tool">
                    <DataQualityTool />
                </Route>
                <Route path="/platform">
                    <Platform />
                </Route>
                <Route path="/solutions">
                    <Solutions />
                </Route>
                <Route path="/industry">
                    <Industry />
                </Route>
                <Route path="/keyfocus">
                    <KeyFocus />
                </Route>
                <Route path="/resources">
                    <Resources />
                </Route>
                <Route path="/recognition">
                    <Recognition />
                </Route>
                <Route path="/company">
                    <Company />
                </Route>
                <Route path="/contact">
                    <ContactUs />
                </Route>
                <Route path="/features">
                    <Features />
                </Route>
                <Route path="/technology">
                    <Technology />
                </Route>
                <Route path="/databrowser">
                    <Databrowser />
                </Route>
            </Switch>
        </>
    );
}
