import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Modal } from "semantic-ui-react";
import icon4 from "../../../resources/dataQualityIcon.png";
import icon3 from "../../../resources/eaciit-databrowser-icon.png";
import ecfmTeaser from "../../../resources/eaciit_hyperscaling_epm.mp4";
import icon2 from "../../../resources/ecfm-home-icon.png";
import playButton from "../../../resources/play-icon.png";
import checkIcon from "../../../resources/right-check.png";
import thumbnail from "../../../resources/homepage/fold1_video.png";
import icon1 from "../../../resources/xPASolutuions.png";

const carouselData = {
    firstCarData: ["Harmonized data from multiple sources"],
    secondCarData: ["Customized client solutions"],
    thirdCarData: ["BI, AI and collaboration tools"],
    fourthCarData: ["Early response and remediation"],
    fifthCarData: ["BI, AI and collaboration tools"],
    sixthCarData: ["Extended planning and analysis"],
};

const SectionOne = () => {
    const [open, setOpen] = useState(false);
    const [animationData, setAnimationData] = useState({
        firstCar: 0,
        secondCar: 0,
        thirdCar: 0,
    });
    const [firstCarouselIndex, setFirstCarouselIndex] = useState(0);
    const [secondCarouselIndex, setSecondCarouselIndex] = useState(0);
    const [thirdCarouselIndex, setThirdCarouselIndex] = useState(0);
    const [fourthCarouselIndex, setFourthCarouselIndex] = useState(0);
    const [fifthCarouselIndex, setFifthCarouselIndex] = useState(0);
    const [sixthCarouselIndex, setSixthCarouselIndex] = useState(0);
    const { firstCarData, secondCarData, thirdCarData, fourthCarData, fifthCarData, sixthCarData } = carouselData;
    const { firstCar, secondCar, thirdCar } = animationData;

    useEffect(() => {}, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // if (firstCarouselIndex === firstCarData.length - 1) {
            // 	setFirstCarouselIndex(0);
            // } else setFirstCarouselIndex(firstCarouselIndex + 1);
            setAnimationData((prevData) => ({
                ...prevData,
                firstCar: firstCar === 0 ? 1 : 0,
            }));
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // if (secondCarouselIndex === secondCarData.length - 1) {
            // 	setSecondCarouselIndex(0);
            // } else setSecondCarouselIndex(secondCarouselIndex + 1);
            setAnimationData((prevData) => ({
                ...prevData,
                secondCar: secondCar === 0 ? 1 : 0,
            }));
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // if (thirdCarouselIndex === thirdCarData.length - 1) {
            // 	setThirdCarouselIndex(0);
            // } else setThirdCarouselIndex(thirdCarouselIndex + 1);
            setAnimationData((prevData) => ({
                ...prevData,
                thirdCar: thirdCar === 0 ? 1 : 0,
            }));
        }, 7000);
        return () => clearInterval(intervalId);
    }, []);

    const handleDemoClick = () => {
        window.location = "/contact";
    };

    const handleDatabrowserClick = () => {
        window.location = "/databrowser";
    };

    const handleDiscoverProductClick = () => {
        window.location = "/product";
    };

    const handleSolutionsOverviewClick = () => {
        window.location = "/solutions";
    };

    const handleDataQualityClick = () => {
        window.location = "/data-quality-tool";
    };

    const renderCheckIcon = (imgSrc) => <img src={imgSrc} alt="Check icon" />;

    return (
        <div className="Homepage-SectionOne-Wrapper">
            <div className="Homepage-SectionOne">
                <div className="Homepage-SectionOne-top">
                    <div className='Homepage-SectionOne-left'>
                        <h4 className='Homepage-SectionOne-left-heading'>
                            Data Harmonization & <br/>Business AI Platform
                        </h4>
                        <div className='Homepage-SectionOne-left-divider' />
                        <p className='Homepage-SectionOne-left-text'>
                            Higher efficiencies in Enterprise Performance Management </p>
                        <div className="btn-group">
                            <button className="schedule-demo-btn" onClick={handleDemoClick}>
                                    Schedule Demo
                            </button>
                            {/*<button className="register-now-btn" onClick={handleDemoClick}>
                                    Register Now
                            </button>*/}
                        </div>
                    </div>
                    <div className="Homepage-SectionOne-right">
                        <div className="eaciit-video-player-wrapper">
                            <Fade top appear={true} delay={500} duration={500} spy={firstCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-1`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{firstCarData[firstCarouselIndex]}</p>
                                </div>
                            </Fade>
                            <Fade right appear={true} delay={1000} duration={500} spy={secondCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-2`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{secondCarData[secondCarouselIndex]}</p>
                                </div>
                            </Fade>
                            <Fade bottom appear={true} delay={1500} duration={500} spy={thirdCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-3`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{thirdCarData[thirdCarouselIndex]}</p>
                                </div>
                            </Fade>
               {/*             <Fade bottom appear={true} delay={2000} duration={500} spy={fourthCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-4`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{fourthCarData[fourthCarouselIndex]}</p>
                                </div>
                            </Fade>*/}
                            {/*<Fade bottom appear={true} delay={1500} duration={500} spy={fifthCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-3`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{fifthCarData[fifthCarouselIndex]}</p>
                                </div>
                            </Fade>*/}
                            <Fade bottom appear={true} delay={1500} duration={500} spy={sixthCarouselIndex}>
                                <div className={`Homepage-SectionOne-right-carousel-card card-5`}>
                                    {renderCheckIcon(checkIcon)}
                                    <p>{sixthCarData[sixthCarouselIndex]}</p>
                                </div>
                            </Fade>
                            
                            <Modal
                                size="large"
                                closeIcon
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                open={open}
                                trigger={<img src={thumbnail} className="video-thumbnail-background" alt="video thumbnail" />}
                            >
                                <Modal.Content image>
                                    <video id="eaciit-video-player" className="eaciit-video-player" playsInline autoPlay controls>
                                        <source src={ecfmTeaser} type="video/mp4" />
                                        Your browser does not support HTML video.
                                    </video>
                                </Modal.Content>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div className="Homepage-SectionOne-bottom">
                    <div className="box-card card-one" onClick={handleSolutionsOverviewClick}>
                        {/*<div className="box-card-header">EACIIT XP&A</div>*/}
                        <div className="box-card-content">
                            <div className="card-icon">
                                <img src={icon1} alt="box-icon" width="40" />
                            </div>
                            <div className="card-description">Smart Command Centre Dashboards </div>
                        </div>
                        <div className="box-card-footer">
                            <span>Discover More </span>
                        </div>
                    </div>
                    <div className="box-card card-two" onClick={handleDiscoverProductClick}>
                        {/*<div className="box-card-header">ECFM</div>*/}
                        <div className="box-card-content">
                            <div className="card-icon">
                                <img src={icon2} alt="box-icon" width="40" />
                            </div>
                            <div className="card-description">Cloud Financial Planning & Analysis, Continuous MIS</div>
                        </div>
                        <div className="box-card-footer">
                            <span>Discover More </span>
                        </div>
                    </div>
                    <div className="box-card card-three" onClick={handleDatabrowserClick}>
                        {/*<div className="box-card-header">Analytics studio</div>*/}
                        <div className="box-card-content">
                            <div className="card-icon">
                                <img src={icon3} alt="box-icon" />
                            </div>
                            <div className="card-description">Packaged applications to Improve KPIs, Earnings & FCF</div>
                        </div>
                        <div className="box-card-footer">
                            <span>Discover More</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionOne;
